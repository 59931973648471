import React from "react";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import { getUserName } from "../../helpers/getUsername";
const Header = (props) => {
    const navigate=useNavigate();
    const {userData}=props;
    return (
        <header>
            <div className="header-nickname">
                <img src="/images/default-avatar.png" className="user-avatar" />

                <span className="user-nickname text-white">{getUserName(userData)}</span>
            </div>
            <div className="header-action">
                <Link to="/earn" style={{ display: "flex" }}>
                    <img src="/images/question.png" />
                </Link>
            </div>
            <div className="header-action">
                <Link to="/air-drop" style={{ display: "flex" }}>
                    <img src="/images/brick.png" />
                </Link>
            </div>
            <div className="header-action text-white font-bold" onClick={()=>navigate("/language")}>
                <span style={{textTransform:"uppercase"}}>{localStorage.getItem("language")}</span>
            </div>
        </header>
    );
};

export default Header;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { uniqid } from "../../helpers/uniqid";
import { useDispatch, useSelector } from "react-redux";
import { deleteWallet, getUserDetails, selectWallet } from "../../Redux/Actions/userActions";
import { USER_DELETE_WALLET_RESET, USER_SELECT_WALLET_RESET } from "../../Redux/Constans/userConstans";

const ChooseWallet = (props) => {
    const tg = window.Telegram.WebApp;
    const dispatch = useDispatch();
    const { setCurrentPage, wallets, id,t } = props;
    const [choosen, setChoosen] = useState(null);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const selectWalletPut = useSelector((state) => state.selectWallet);
    const {
        loading: loadingWallet,
        success: successWallet,
        error: errorWallet,
    } = selectWalletPut;
    const walletDelete=useSelector(state=>state.walletDelete);
    const {loading:loadingDelete,
        success:successDelete,
        error:errorDelete}    =walletDelete;
    const userDetails = useSelector((state) => state.userDetails);
    const { loading: loadingUser, data } = userDetails;
    const chooseWalletHandler = () => {
        if (choosen) {
          
            dispatch(selectWallet({ walletAddress: choosen, id }));
        }
    };
    const deleteWalletHandler=()=>{
        if(choosen){
        
            dispatch(deleteWallet({walletAddress:choosen,id}));
        }
    };
   
    useEffect(()=>{
      
        if(!loadingDelete&&successDelete){
            dispatch({type:USER_DELETE_WALLET_RESET});
        }
        if(!loadingWallet&&successWallet){
            dispatch({type:USER_SELECT_WALLET_RESET});
        }


    },[dispatch,selectWalletPut,walletDelete]);

    

    // useEffect(() => {
    //     if (!loadingWallet && typeof loadingWallet !== "undefined") {
    //         if (successWallet) {
    //             dispatch(getUserDetails(tg.initDataUnsafe.user));
    //         } else if (errorWallet) {
    //             setError(errorWallet);
    //         }
    //     }
    // }, [dispatch, selectWalletPut]);

    // useEffect(() => {
    //     if (
    //         !loadingWallet &&
    //         !loadingUser &&
    //         typeof loadingUser !== "undefined" &&
    //         typeof loadingWallet !== "undefined"
    //     ) {
    //         dispatch({ type: USER_SELECT_WALLET_RESET });
    //     }
    // }, [selectWalletPut, userDetails]);


    



 

    // useEffect(()=>{
    //     if(!loadingDelete&&typeof loadingDelete!=="undefined"){
    //         if(successDelete){
    //             dispatch(getUserDetails(tg.initDataUnsafe.user));
    //         }else{
    //             setError(errorWallet);
    //         }
    //     }
    // },[dispatch,walletDelete]);

    // useEffect(()=>{
    //     if(!loadingDelete&&!loadingUser&&typeof loadingDelete!=="undefined"&&typeof loadingUser!=="undefined"){
    //         dispatch({type:USER_DELETE_WALLET_RESET});
    //     }
    // },[walletDelete,userDetails]);

    return (
        <React.Fragment>
            <div className="add-wallet_header">
                <span>{t("add-wallet_header-choose")}</span>
                <img
                    src="/images/cross.png"
                    width="16px"
                    height="16px"
                    onClick={() => navigate("/")}
                />
            </div>
            <div className="separator"></div>
            <p className="add-wallet_text ">
                {t("add-wallet_text-choose")}
            </p>
            <div className="choose-wallet">
                {wallets.map((item, index) => (
                    <div
                        className={`choose-wallet_wallet ${
                            item.choosen ? "active" : ""
                        } ${choosen == item.address ? "choosen" : ""}`}
                        key={uniqid()}
                        onClick={() => setChoosen(item.address)}
                    >
                        <span>{cutWallet(item.address)}</span>
                        <span className="choose-wallet_network">
                            USDT TRC-20
                        </span>
                    </div>
                ))}
            </div>
            <div
                className="add-wallet_connect-white"
                onClick={() => setCurrentPage(1)}
            >
                {t("add-wallet_connect-white")}
            </div>
            <div className="add-wallet_buttons add-wallet_buttons-choose">
                <div
                    className="add-wallet_buttons-button text-white"
                    onClick={chooseWalletHandler}
                >
                    {t("add-wallet_buttons-button-choose1")}
                </div>
                <div className="add-wallet_buttons-button text-white" onClick={deleteWalletHandler}>
                    {t("add-wallet_buttons-button-choose2")}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ChooseWallet;

const cutWallet = (address = "") => {
    let wallet = "";
    wallet += address.substring(0, 8);
    wallet += "...";
    wallet += address.substring(address.length - 7);
    return wallet;
};

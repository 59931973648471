import React from "react";
import "./homebuttons.css";
import { useNavigate } from "react-router";
const HomeButtons = (props) => {
    const {t}=props;
    const navigate=useNavigate();
    return (
        <div style={{marginBottom:"1.5rem"}}>
            <div className="home-buttons">
                <div className="home-button">
                    <div className="home-button_bg">
                        <img src="/images/pipe.png" />
                    </div>
                    <p>{t("home-button-home")}</p>
                </div>
                <div className="home-button" onClick={()=>navigate("/top-users")}>
                    <div className="home-button_bg">
                        <img src="/images/poop.png" />
                    </div>
                    <p>{t("home-button-top")}</p>
                </div>
                <div className="home-button" onClick={()=>navigate("/invite-friends")}>
                    <div className="home-button_bg">
                        <img src="/images/mushroom.png" />
                    </div>
                    <p>{t("home-button-invite")}</p>
                </div>
                <div className="home-button" onClick={()=>navigate("/tasks")}>
                    <div className="home-button_bg">
                        <img src="/images/coin.png" />
                    </div>
                    <p>{t("home-button-earn")}</p>
                </div>
            </div>
        </div>
    );
};

export default HomeButtons;

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_ADD_WALLET_REQUEST = "USER_ADD_WALLET_REQUEST";
export const USER_ADD_WALLET_SUCCESS = "USER_ADD_WALLET_SUCCESS";
export const USER_ADD_WALLET_FAIL = "USER_ADD_WALLET_FAIL";
export const USER_ADD_WALLET_RESET = "USER_ADD_WALLET_RESET";


export const USER_SELECT_WALLET_REQUEST = "USER_SELECT_WALLET_REQUEST";
export const USER_SELECT_WALLET_SUCCESS = "USER_SELECT_WALLET_SUCCESS";
export const USER_SELECT_WALLET_FAIL = "USER_SELECT_WALLET_FAIL";
export const USER_SELECT_WALLET_RESET = "USER_SELECT_WALLET_RESET";

export const USER_DELETE_WALLET_REQUEST = "USER_DELETE_WALLET_REQUEST";
export const USER_DELETE_WALLET_SUCCESS = "USER_DELETE_WALLET_SUCCESS";
export const USER_DELETE_WALLET_FAIL = "USER_DELETE_WALLET_FAIL";
export const USER_DELETE_WALLET_RESET = "USER_DELETE_WALLET_RESET";

export const USERS_TOP_REQUEST = "USERS_TOP_REQUEST";
export const USERS_TOP_SUCCESS = "USERS_TOP_SUCCESS";
export const USERS_TOP_FAIL = "USERS_TOP_FAIL";
export const USERS_TOP_RESET = "USERS_TOP_RESET";

export const GET_REFERRALS_REQUEST = "GET_REFERRALS_REQUEST";
export const GET_REFERRALS_SUCCESS = "GET_REFERRALS_SUCCESS";
export const GET_REFERRALS_FAIL = "GET_REFERRALS_FAIL";
export const GET_REFERRALS_RESET = "GET_REFERRALS_RESET";
import React from "react";

const HowToEarnSlide1 = (props) => {
    const {t}=props;
    return (
        <React.Fragment>
            <h1 className="earn-text text-black">
           {t("earn-text1")} <span className="text-red">MARIO</span> {t("earn-text2")}
               
            </h1>
            <img src="/images/mario-present.png" className="earn_img" />
        </React.Fragment>
    );
};

export default HowToEarnSlide1;

import React, { useState } from "react";
import HowToEarnPlate from "../components/howtoearnplate/howToEarnPlate";
import "./earn.css";
import { Link, Navigate } from "react-router-dom";
import HowToEarnSlide1 from "../components/howtoearnslides/howtoearnslide1";
import HowToEarnSlide2 from "../components/howtoearnslides/howtoearnslide2";
import HowToEarnSlide3 from "../components/howtoearnslides/howtoearnslide3";
import { uniqid } from "../helpers/uniqid";
const HowToEarn = (props) => {
    const {t}=props;
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlideHandler = () => {
        setCurrentSlide(currentSlide + 1);
    };

    const getCurrentSlide = (slide) => {
        switch (slide) {
            case 0:
                return <HowToEarnSlide1 t={t}/>;
            case 1:
                return <HowToEarnSlide2 t={t}/>;
            case 2:
                return <HowToEarnSlide3 t={t}/>;
            default:
                return <Navigate to="/" />;
        }
    };
    console.log(Array.apply(null, Array(3)));
    return (
        <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            <HowToEarnPlate t={t}/>

            {getCurrentSlide(currentSlide)}
            <div className="earn-nav">
                <div className="earn-nav_slider">
                    {Array.apply(null, Array(3)).map((item, index) => (
                        <div
                            key={uniqid()}
                            className={index == currentSlide ? "active" : ""}
                        ></div>
                    ))}
                </div>
                <div className="earn_buttons">
                    <div className={`earn_button-skip ${currentSlide==2?"display-none":""}`}>
                        <Link to="/">{t("earn_button-skip")}</Link>
                    </div>
                    <div
                        className={`earn_button-next ${currentSlide==2?"earn_button-next-full":""}`}
                        onClick={nextSlideHandler}
                    >
                        <img src="/images/bucket-plant-left.png" />
                        <span className="font-bold">{currentSlide==2?t("earn_button-ok"):t("earn-button-next")}
                            </span>
                        {currentSlide==2?<img src="/images/bucket-plant-right.png" />:null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToEarn;



import React, { useState } from "react";
import { useNavigate } from "react-router";
import { botLink } from "../../App";
import { CopyToClipboard } from "react-copy-to-clipboard";
const InviteFriendsEmpty = (props) => {
    const [modalOpened, setModalOpened]=useState(false);
    const {setDarkActive,t}=props;
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();
   
    const [copied, setCopied] = useState(false);

    const copyHandler = () => {
       
        setCopied(true);

        setTimeout(() => {
            "set timeout";
            setCopied(false);
        }, 500);
    };

    return (
        <React.Fragment>
            <div className="invite-friends_header">
                {t("invite-friends_header")} - {" "}
                <img src="/images/star.png" width="24px" height="24px" style={{verticalAlign:"middle"}}/>
                <img
                    src="/images/cross.png"
                    width="16px"
                    height="16px"
                    onClick={() => navigate("/")}
                    style={{ position: "absolute", right: 0, top: 0 }}
                />
            </div>
            <div className="separator"></div>

            <img src="/images/mario-alot.png" />

            <div className="separator-small"></div>
            <p className="invite-friends_text">
                {t("invite-friends_text")}
            </p>
            <div
                className="invite-friends_button"
                onClick={() => {setModalOpened(true);setDarkActive(true)}}
            >
                {t("invite-friends_button")}
            </div>
            <div
                className={`invite-friend_modal ${
                    modalOpened ? "" : "display-none"
                }`}
                style={{zIndex:"2"}}
            >
                <div
                    className="invite-friends_modal-header"
                    style={{ position: "relative" }}
                >
                    {t("invite-friends_modal-header")}
                    <img
                        src="/images/cross.png"
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "16px",
                            height: "16px",
                        }}
                        onClick={() => {setModalOpened(false);setDarkActive(false)}}
                    />
                </div>
                <div
                    className="separator"
                    style={{ marginTop: "1.5rem" }}
                ></div>
                <CopyToClipboard
                    text={`${botLink}?start=${tg.initDataUnsafe.user.id}`}
                    onCopy={copyHandler}
                >
                    <div className={`invite-friends_modal-button ${copied?"active":""}`}>
                        {t("invite-friends_modal-button")}
                    </div>
                </CopyToClipboard>
                <div className="invite-friends_modal-button">
                    <a
                        href={`https://t.me/share/url?url=${botLink}?start=${tg.initDataUnsafe.user.id}&text=${t("invite-friends_modal-button-button")}`}
                    >
                        {t("invite-friends_modal-button-text")}
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default InviteFriendsEmpty;

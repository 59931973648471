import React, { useState } from "react";
import "./newuser.css";
import NewUserSlide1 from "../components/newuser/newUserSlide1";
import NewUserSlide2 from "../components/newuser/newUserSlide2";
import NewUserSlide3 from "../components/newuser/newUserSlide3";
import { useNavigate } from "react-router";
const NewUser = (props) => {

    const { userDetails,t } = props;
    const { data } = userDetails;
    console.log(userDetails);
    const navigate = useNavigate();
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlideHandler = () => {
        if (currentSlide == 2) {
            navigate("/");
        }
        setCurrentSlide(currentSlide + 1);
    };
    const getButtonText = (slide) => {
        switch (slide) {
            case 0:
                return t("new-user-button-go");
            default:
                return t("new-user-button-continue");
        }
    };
    
    const showCurrentSlide = (slide) => {
        switch (slide) {
            case 0:
                return <NewUserSlide1 t={t}/>;
            case 1:
                return <NewUserSlide2 t={t}/>;
            case 2:
                return <NewUserSlide3 data={data} t={t}/>;
        }
    };

    return (
        <div
            className="new-user"
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            {showCurrentSlide(currentSlide)}
            <div className="new-user_next" onClick={nextSlideHandler}>
                <img
                    src="/images/bucket-plant-left.png"
                    className="new-user_nets-image"
                />
                {getButtonText(currentSlide)}
                <img
                    src="/images/bucket-plant-right.png"
                    className="new-user_nets-image"
                />
            </div>
        </div>
    );
};

export default NewUser;


import React, { useEffect, useState } from "react";
import "./addwallet.css";
import AddWalletEmpty from "../components/addwallet/addWalletEmpty";
import AddNewWallet from "../components/addwallet/addNewWallet";
import ChooseWallet from "../components/addwallet/chooseWallet";
import { userDetailsReducer } from "../Redux/Reducers/userReducers";

/*
        0 - empty


*/

const AddWallet = (props) => {
    const [currentPage, setCurrentPage] = useState(0);

    const { userDetails,t } = props;
    const { data } = userDetails;
    const { wallets } = data;
    console.log(wallets);

    useEffect(() => {
        if(!userDetails.loading&&typeof userDetails.loading!=="undefined"){
            if (wallets.length == 0) {
                setCurrentPage(0);
            }else{
                setCurrentPage(2);
            }

        }
    }, [wallets]);

    const getCurrentPage = (page) => {
        switch (page) {
            case 0:
                return <AddWalletEmpty setCurrentPage={setCurrentPage} t={t}/>;
            case 1:
                return (
                    <AddNewWallet
                        setCurrentPage={setCurrentPage}
                        id={data.id}
                        t={t}
                    />
                );
            case 2:
                return (
                    <ChooseWallet
                        setCurrentPage={setCurrentPage}
                        wallets={wallets}
                        id={data.id}
                        t={t}
                    />
                );
        }
    };

    return (
        <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            <div className="add-wallet window-big">{getCurrentPage(currentPage)}</div>
        </div>
    );
};

export default AddWallet;

import {
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_RESET,
    USER_DETAILS_SUCCESS,
    USER_ADD_WALLET_REQUEST,
    USER_ADD_WALLET_SUCCESS,
    USER_ADD_WALLET_FAIL,
    USER_ADD_WALLET_RESET,
    USER_SELECT_WALLET_REQUEST,
    USER_SELECT_WALLET_SUCCESS,
    USER_SELECT_WALLET_FAIL,
    USER_SELECT_WALLET_RESET,
    USER_DELETE_WALLET_REQUEST,
    USER_DELETE_WALLET_SUCCESS,
    USER_DELETE_WALLET_FAIL,
    USER_DELETE_WALLET_RESET,
    USERS_TOP_REQUEST,
    USERS_TOP_SUCCESS,
    USERS_TOP_FAIL,
    USERS_TOP_RESET,
    GET_REFERRALS_REQUEST,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAIL,
    GET_REFERRALS_RESET,
} from "../Constans/userConstans";

export const userDetailsReducer = (
    state = { data: {}, isNewUser: false },
    action
) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return { ...state, loading: true };
        case USER_DETAILS_SUCCESS:
            return {
                loading: false,
                data: action.payload.user,
                isNewUser: action.payload.isNewUser,
            };
        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case USER_DETAILS_RESET:
            return { ...state };
        default:
            return state;
    }
};

export const addNewWalletReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_ADD_WALLET_REQUEST:
            return { loading: true };
        case USER_ADD_WALLET_SUCCESS:
            return { loading: false, success: true };
        case USER_ADD_WALLET_FAIL:
            return { loading: false, error: action.payload };
        case USER_ADD_WALLET_RESET:
            return {};
        default:
            return state;
    }
};

export const selectWalletReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SELECT_WALLET_REQUEST:
            return { loading: true };
        case USER_SELECT_WALLET_SUCCESS:
            return { loading: false, success: true };
        case USER_SELECT_WALLET_FAIL:
            return { loading: false, success: false, error: action.payload };
        case USER_SELECT_WALLET_RESET:
            return {};
        default:
            return state;
    }
};
export const deleteWalletReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_DELETE_WALLET_REQUEST:
            return { loading: true };
        case USER_DELETE_WALLET_SUCCESS:
            return { loading: false, success: true };
        case USER_DELETE_WALLET_FAIL:
            return { loading: false, success: false, error: action.payload };
        case USER_DELETE_WALLET_RESET:
            return {};
        default:
            return state;
    }
};

export const getUsersTopReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USERS_TOP_REQUEST:
            return { loading: true };
        case USERS_TOP_SUCCESS:
            return { loading: false, users: action.payload };
        case USERS_TOP_FAIL:
            return { loading: false, error: action.payload };
        case USERS_TOP_RESET:
            return { users: [] };
        default:
            return state;
    }
};

export const getUserReferralsReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case GET_REFERRALS_REQUEST:
            return { loading: true,users: [] };
        case GET_REFERRALS_SUCCESS:
            return { loading: false, users: action.payload };
        case GET_REFERRALS_FAIL:
            return { loading: false, error: action.payload };
        case GET_REFERRALS_RESET:
            return { users: [] };
        default:
            return state;
    }
};

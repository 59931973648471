import React from "react";

const NewUserSlide1 = (props) => {
    const {t}=props;
    return (
        <React.Fragment>
            <div className="new-user_hello">
                <p>{t("new-user-hello")}</p>
                <p>{t("new-user-hello-under")}</p>
            </div>
            <img src="/images/mario-fire.png" className="mario-image" />
        </React.Fragment>
    );
};

export default NewUserSlide1;

import React, { useEffect } from "react";
import "./tokens.css";
import { useDispatch, useSelector } from "react-redux";
import { formatTokens } from "../../helpers/formatTokens";

const Tokens = (props) => {
   const {tokens}=props;

    return (
        <div className="tokens-main">
            <div className="tokens">
                <div>
                    <span>{formatTokens(tokens)}</span>
                </div>
                <img src="/images/star.png" />
                <div>
                    <span>MARIO</span>
                </div>
            </div>
        </div>
    );
};

export default Tokens;




// Примеры использования
console.log(formatTokens(562));    // "562"
console.log(formatTokens(1562));   // "1,562"
console.log(formatTokens(1234567)); // "1,234,567"



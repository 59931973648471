import React from "react";
import "./earnplate.css";
const HowToEarnPlate = (props) => {
    const {t}=props;
    return (
        <div className="earn-plate text-black">
            <img src="/images/star.png" />
            <span>{t("earn-plate")}</span> <img src="/images/star.png" />
     

        </div>
    );
};

export default HowToEarnPlate;

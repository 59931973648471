import {
        TASK_CHECK_FAIL,
        TASK_CHECK_REQUEST,
    TASK_CHECK_RESET,
    TASK_CHECK_SUCCESS,
    TASK_LIST_FAIL,
    TASK_LIST_REQUEST,
    TASK_LIST_RESET,
    TASK_LIST_SUCCESS,
} from "../Constans/taskConstants";

export const getTasksReducer = (state = { tasks: [] }, action) => {
    switch (action.type) {
        case TASK_LIST_REQUEST:
            return { tasks: [], loading: true };
        case TASK_LIST_SUCCESS:
            return {
                loading: false,
                tasks: action.payload,
            };
        case TASK_LIST_FAIL:
            return { loading: false, error: action.payload };
        case TASK_LIST_RESET:
            return { tasks: [] };
        default:
            return state;
    }
};

export const checkTaskReducer = (state = {  }, action) => {
        switch (action.type) {
            case TASK_CHECK_REQUEST:
                return {  loading: true };
            case TASK_CHECK_SUCCESS:
                return {
                    loading: false,
                    success: true,
                };
            case TASK_CHECK_FAIL:
                return { loading: false, error: action.payload };
            case TASK_CHECK_RESET:
                return { };
            default:
                return state;
        }
    };
    

const preloadImageWithPromise = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = reject;
    });
};

export const preloadMultipleImages = async (imagesArray, callback) => {
    try {
        await Promise.all(
            imagesArray.map((src) => preloadImageWithPromise(src))
        );
        console.log("promised");
        
        callback();
    } catch (error) {
        console.error("An error occurred while preloading images", error);
        callback();
    }
};

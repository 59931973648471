import React from "react";

const HowToEarnSlide2=(props)=>{
  const {t}=props;
        return ( <React.Fragment>
                <h1 className="earn-text text-black">
                  {t("earn-text3")} <span className="text-red">%</span> {t("earn-text4")}
                </h1>
                <img src="/images/mario-green.png" className="earn_img" />
            </React.Fragment>)
};

export default HowToEarnSlide2;
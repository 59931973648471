import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    addNewWallet as addNewWalletRedux,
    getUserDetails,
} from "../../Redux/Actions/userActions";
import { USER_ADD_WALLET_RESET } from "../../Redux/Constans/userConstans";
const AddNewWallet = (props) => {
    const tg = window.Telegram.WebApp;
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.userDetails);
    const { loading: loadingUser, data } = userDetails;
    const [errorAdd, setErrorErr] = useState("");
    const { setCurrentPage, id,t } = props;
    const [walletAddress, setWalletAddress] = useState("");
    const [adviceOpened, setAdviceOpened] = useState(false);
    const navigate = useNavigate();
    const addWallet = useSelector((state) => state.addWallet);
    const { success, loading, error } = addWallet;
    const addWalletHandler = () => {
        if (walletAddress.length == 34) {
            dispatch(addNewWalletRedux({ walletAddress, id }));
        } else {
            setErrorErr(t("add-wallet-error"));
        }
    };

    useEffect(() => {
    
        if (typeof loading !== "undefined" && success && !loading) {
            dispatch(getUserDetails(tg.initDataUnsafe.user));
        } else if (typeof loading !== "undefined" && error && !loading) {
           
            setErrorErr(error);
            dispatch({ type: USER_ADD_WALLET_RESET });
        }
    }, [dispatch, addWallet]);
  
    useEffect(() => {
        if (
            !loadingUser &&
            !loading &&
            typeof loading !== "undefined" &&
            typeof loadingUser !== "undefined" &&
            success
        ) {
       
            dispatch({ type: USER_ADD_WALLET_RESET });
            setCurrentPage(2);
        }
    }, [dispatch, userDetails, addWallet]);
    return (
        <React.Fragment>
            <div className={`dark ${adviceOpened?"active":""}`} style={{zIndex:"1"}}></div>
            <div className="add-wallet_header">
                <span>{t("add-wallet_header-new")}</span>
                <img
                    src="/images/cross.png"
                    width="16px"
                    height="16px"
                    onClick={() => navigate("/")}
                />
            </div>
            <div className="separator"></div>
            <p className="add-wallet_text ">
                {t("add-wallet_text-new")}{" "}
                <span className="font-bold">USDT TRC-20</span> {t("add-wallet_text-new2")}
            </p>

            <input
                className={`add-wallet_input ${
                    adviceOpened ? "display-none" : ""
                }`}
                type="text"
                placeholder={t("add-wallet_input")}
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
            />
            <p
                className="text-red"
                style={{ textAlign: "end", marginTop: ".5rem" }}
            >
                {errorAdd}
            </p>
            <div className={`advice ${adviceOpened ? "" : "display-none"}`} style={{zIndex:"2"}}>
                <div className="advice-header">
                
                    <span>{t("advice-header")}</span>
                    <img
                        src="/images/cross.png"
                        onClick={() => setAdviceOpened(false)}
                    />
                </div>
                <p className="font-bold advice_where">{t("advice_where")}</p>
                <p className="advice_instruction">
                    {t("advice_instruction")}
                </p>
                <div className="button-go"><a href="https://t.me/super_mario_community">{t("button-go")}</a></div>
            </div>

            <div className="add-wallet_buttons add-new-wallet">
                <div
                    className="add-wallet_buttons-button add-wallet_buttons-button_question"
                    onClick={() => setAdviceOpened(true)}
                >
                    <img src="/images/question.png" />
                </div>
                <div
                    className="add-wallet_buttons-button text-white add-new-wallet_button"
                    onClick={addWalletHandler}
                >
                    {t("add-wallet_buttons-button")}
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddNewWallet;

import axios from "axios";
import { serverLink } from "../../App";
import {
        TASK_CHECK_REQUEST,
    TASK_CHECK_SUCCESS,
    TASK_LIST_REQUEST,
    TASK_LIST_SUCCESS,
} from "../Constans/taskConstants";

export const getTasksList = (props) => async (dispatch) => {
    try {
        dispatch({ type: TASK_LIST_REQUEST });

        const { data } = await axios.post(serverLink + "/api/task",props);

        dispatch({ type: TASK_LIST_SUCCESS, payload: data });
    } catch (error) {
        window.Telegram.WebApp.close();
    }
};
export const checkTaskList = (props) => async (dispatch) => {
        try {
            dispatch({ type: TASK_CHECK_REQUEST });
    
            const { data } = await axios.post(serverLink + "/api/task/check",props);
    
            dispatch({ type: TASK_CHECK_SUCCESS, payload: data });
            dispatch({ type: TASK_LIST_SUCCESS, payload: data });
        } catch (error) {
            window.Telegram.WebApp.close();
        }
    };
    
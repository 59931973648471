import React from "react";
import "./marioimage.css";
const MarioMain = () => {
    return (
       
            <img src="/images/mario-main.png" className="mario-image_main"/>
     
    );
};

export default MarioMain;

import React from "react";
import "./dailybonus.css";
const DailyBonus = (props) => {
    const { dailyBonusOpened, setDailyBonusOpened,t } = props;

    return (
        <div
            className={`daily-bonus ${dailyBonusOpened ? "" : "display-none"}`}
        >
            <img src="/images/cross.png" style={{position:"absolute",top:"5%",right:"5%",width:"16px"}} onClick={()=>setDailyBonusOpened(false)}/>
            <div className="daily-bonus_amount">
                <span>+100</span> <img src="/images/star.png" />
                <span>MARIO</span>
            </div>
            <p>{t("daily-bonus-text1")}</p>
            <p>
            {t("daily-bonus-text2")} <img src="/images/star.png" /> - MARIO
            </p>
            <div className="button-close" onClick={()=>setDailyBonusOpened(false)}>
                {" "}
                <img src="/images/bucket-plant-left.png" />
                <span>{t("daily-bonus-close")}</span>
                <img src="/images/bucket-plant-right.png" />
            </div>
        </div>
    );
};

export default DailyBonus;

import React, { useEffect, useRef, useState } from "react";

const NewUserSlide2 = (props) => {
    const {t}=props;
    const [countFriends, setCountFriends] = useState(0);
    const [analyzeActivity, setAnalyzeActivity] = useState(0);
    const [checkPremium, setCheckPremium] = useState(0);

    const countFriendsRef = useRef();
    const analyzeActivityRef=useRef();
    const checkPremiumRef=useRef();
    useEffect(() => {
        return () => clearInterval(countFriendsRef.current);
    }, []);

    useEffect(() => {
        countFriendsRef.current = setInterval(() => {
            
            setCountFriends((prev) => {
                if (prev >= 100) {
                    clearInterval(countFriendsRef.current);
                    return prev; // Возвращаем текущее значение, чтобы остановить инкремент
                }
                return prev + 1;
            });
        }, 50);

        analyzeActivityRef.current=setInterval(()=>{
            setAnalyzeActivity((prev) => {
                if (prev >= 100) {
                    clearInterval(analyzeActivityRef.current);
                    return prev; // Возвращаем текущее значение, чтобы остановить инкремент
                }
                return prev + 1;
            });
        },40);
        checkPremiumRef.current=setInterval(()=>{
            setCheckPremium((prev) => {
                if (prev >= 100) {
                    clearInterval(checkPremiumRef.current);
                    return prev; // Возвращаем текущее значение, чтобы остановить инкремент
                }
                return prev + 1;
            });
        },70);
    }, []);

    return (
        <React.Fragment>
            <div className="new-user_account-check" style={{textAlign:"center"}}>
                {t("new-user_account-check")}
            </div>
            <img
                src="/images/mario-incoin.png"
                className="new-user_mario-incoin"
            />

            <div className="new-user_calculation">
                <div className="new-user_calculation-item">
                    <span>{t("new-user_calculation-item1")}</span>
                    <span className="status">{countFriends}%</span>
                </div>
                <div className="separator" style={{width:`${countFriends}%`}}></div>
                <div className="new-user_calculation-item">
                    <span>{t("new-user_calculation-item2")}</span>
                    <span className="status">{analyzeActivity}%</span>
                </div>
                <div className="separator" style={{width:`${analyzeActivity}%`}}></div>
                <div className="new-user_calculation-item">
                {t("new-user_calculation-item3")}
                    <span className="status">{checkPremium}%</span>
                </div>
                <div className="separator" style={{width:`${checkPremium}%`}}></div>
            </div>
        </React.Fragment>
    );
};

export default NewUserSlide2;

import React, { useEffect } from "react";
import "./topusers.css";
import { useDispatch, useSelector } from "react-redux";
import { getTopUsers } from "../Redux/Actions/userActions";
import { uniqid } from "../helpers/uniqid";
import { useNavigate } from "react-router";
import { formatTokens } from "../helpers/formatTokens";
import { getUserName } from "../helpers/getUsername";
const TopUsers = (props) => {
        const navigate=useNavigate();
    const { userDetails,t } = props;
    const { data } = userDetails;
    const { tokens } = data;
    const dispatch = useDispatch();
    const tg = window.Telegram.WebApp;
    const usersTop = useSelector((state) => state.usersTop);
    const { loading, users, error } = usersTop;

  
    console.log(usersTop);
    return (
        <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            <div className="window-big top-users">
                <div className="top-users_header text-white font-bold" style={{position:"relative"}}>
                    {t("top-users_header")}
                    <img src="/images/cross.png" width="16px" style={{position:"absolute",right:"0"}} onClick={()=>navigate("/")}/>
                </div>

                <div className="top-users_me">
                    <div className="top-users_me-avatar">
                        <img src="/images/default-avatar.png" />
                    </div>
                    <div className="top-users_me-nickname">
                        <span className="font-bold">{getUserName(data)}</span>

                        <span>
                            {formatTokens( tokens)}
                            <img
                                src="/images/star.png"
                                style={{ width: "16px", height: "16px",margin:"0 .25rem" }}
                            />
                            MARIO
                        </span>
                    </div>
                    <span className="top-users_me-place">#{data.top}</span>
                </div>
                <div className="separator"></div>

                <p className="top-users_holders">{!loading?users.length:null} {t("top-users_holders")}</p>
                <div className="top-users_list"> 
                    {!loading?users.map((item,index) => (
                        <div className="top-users_me top-users_item" key={uniqid()}>
                            <div className="top-users_me-avatar">
                                <img src="/images/default-avatar.png" />
                            </div>
                            <div className="top-users_me-nickname">
                                <span className="font-bold">
                                     {getUserName(item)}
                                </span>

                                <span>
                                    {formatTokens( item.tokens)}
                                    <img
                                        src="/images/star.png"
                                        style={{
                                            width: "16px",
                                            height: "16px",
                                            margin:"0 .25rem"
                                        }}
                                    />
                                    MARIO
                                </span>
                            </div>
                            <span className="top-users_me-place ">#{index+1}</span>
                        </div>
                    )):null}
                </div>
            </div>
        </div>
    );
};

export default TopUsers;

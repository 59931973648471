import React from "react";
import Tokens from "../tokens/tokens";

const NewUserSlide3 = (props) => {
    const { data,t } = props;
    const {tokens}=data;

    return (
      <React.Fragment>
   <Tokens tokens={tokens}/>
            <div className="new-user_plate">
                <p>{t("new-user_plate1")}</p>
                <p>{t("new-user_plate2")}</p>
            </div>
            <img src="/images/mario-star-hand.png" className="new-user_mario-star-hand"/>
      </React.Fragment>
         
        
    );
};

export default NewUserSlide3;

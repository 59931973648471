import React from "react";

const HowToEarnSlide3 = (props) => {
    const {t}=props;
    return (
        <React.Fragment>
            <h1 className="earn-text text-black">
                {t("earn-text5")}<span className="text-red">MARIO</span> {t("earn-text6")}
            </h1>
            <img src="/images/mario-star-hand.png" className="earn_img" />
        </React.Fragment>
    );
};

export default HowToEarnSlide3;

import React from "react";
import { useNavigate } from "react-router";

const AddWalletEmpty = (props) => {
    const { setCurrentPage,t } = props;
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <div className="add-wallet_header">
                <span>{t("add-wallet_header")}</span>
                <img
                    src="/images/cross.png"
                    width="16px"
                    height="16px"
                    onClick={() => navigate("/")}
                />
            </div>
            <div className="separator"></div>
            <p className="add-wallet_text ">{t("add-wallet_text")}</p>

            <div
                className="add-wallet_connect text-white"
                onClick={() => setCurrentPage(1)}
            >
                {t("add-wallet_connect")}
            </div>

            <img src="/images/mario-flag.png" className="mario-flag" />

            <div className="add-wallet_buttons">
                <div className="add-wallet_buttons-button text-white">
                    {t("add-wallet_buttons-button1")}
                </div>
                <div className="add-wallet_buttons-button text-white">
                    {t("add-wallet_buttons-button2")}
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddWalletEmpty;

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./Redux/store";
import { Provider } from "react-redux";
import "./18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
    <Suspense >

        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>
    </BrowserRouter>
);

import React from "react";
import "./airdrop.css";
import { useNavigate } from "react-router";

const AirDrop = (props) => {
    const { t } = props;
    const navigate = useNavigate();
    return (
        <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            <div className="window-big airdrop">
                <img
                    src="/images/cross.png"
                    className="close-window"
                    onClick={() => navigate("/")}
                />
                <div className="airdrop-header">
                    {t("airdrop-convert")}{" "}
                    <span className="text-red">MARIO</span>
                    <img src="/images/star.png" />
                    {t("airdrop-convert_in")}{" "}
                    <span className="text-green">USDT</span>
                </div>
                <div className="plate-airdrop">01/10/2024</div>
                <img
                    src="/images/mario-present-green.png"
                    className="mario-present-green_image"
                />
                <div
                    className={`earn_button-next earn_button-next-full button-close`}
                    onClick={() => navigate("/")}
                >
                    <img src="/images/bucket-plant-left.png" />
                    <span className="font-bold">
                        {t("airdrop-close_button")}
                    </span>
                    <img src="/images/bucket-plant-right.png" />
                </div>
            </div>
        </div>
    );
};

export default AirDrop;

import React, { useEffect, useState } from "react";
import "./language.css";
import { useNavigate } from "react-router";
import { languages } from "../App";
import { uniqid } from "../helpers/uniqid";
const Language = (props) => {
    const {t,changeLanguage}=props;
    const navigate = useNavigate();
    const [selectedLanguage, setSelectedLanguage] = useState(
""
    );
    console.log(selectedLanguage);

    const changeLanguageHandler=()=>{
        localStorage.setItem("language",selectedLanguage);
        changeLanguage(selectedLanguage)
        setSelectedLanguage("");
    }

    return (
        <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            <div className="window-big language">
                <div className="language-header">
                    {t("language-header")}
                    <img
                        src="/images/cross.png"
                        onClick={() => navigate("/")}
                    />
                </div>

                <div className="separator"></div>
                <p className="language-text">{t("language-text")}</p>
                {languages.map((item) => (
                    <div className={`language-item ${selectedLanguage==item.code?"active":""}`} key={uniqid()} onClick={()=>setSelectedLanguage(item.code)}>
                        <img src={`/images/${item.image}`} />
                        <span>{item.name}</span>
                        <img
                            src="/images/checked.svg"
                            className={`${
                                localStorage.getItem("language") == item.code
                                    ? ""
                                    : "display-none"
                            }`}
                            style={{marginLeft:"auto"}}
                        />
                    </div>
                ))}

                <div className="language-button_save font-bold" onClick={changeLanguageHandler} >{t("language-button_save")}</div>
            </div>
        </div>
    );
};
export default Language;
var getKeys = function (obj) {
    var keys = [];
    for (var key in obj) {
        keys.push(key);
    }
    return keys;
};

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { addNewWalletReducer, deleteWalletReducer, getUserReferralsReducer, getUsersTopReducer, selectWalletReducer, userDetailsReducer } from "./Reducers/userReducers";
import { checkTaskReducer, getTasksReducer } from "./Reducers/tasksReducers";

const reducer = combineReducers({
    userDetails: userDetailsReducer,
    addWallet:addNewWalletReducer,
    selectWallet:selectWalletReducer,
    walletDelete:deleteWalletReducer,
    usersTop:getUsersTopReducer,
    userReferrals:getUserReferralsReducer,
    tasksList:getTasksReducer,
    checkTask:checkTaskReducer
});

const middleware = [thunk];

const store = createStore(
    reducer,
    {},
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
import React, { useCallback, useEffect, useState } from "react";
import "./invitefriends.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getReferrals } from "../Redux/Actions/userActions";
import InviteFriendsEmpty from "../components/invitefriends/inviteFriendsEmpty";
import InviteFriendsMain from "../components/invitefriends/inviteFriendsMain";
const InviteFriends = (props) => {
    const [darkActive,setDarkActive]=useState(false);
    const { referrals,t } = props;
    console.log(referrals);
   
    const dispatch = useDispatch();
    const userReferrals = useSelector((state) => state.userReferrals);
    const { loading, users, error } = userReferrals;

    const getPageToShow = useCallback(() => {
        if (referrals.length == 0) {
            return <InviteFriendsEmpty setDarkActive={setDarkActive} t={t}/>;
        }
        return <InviteFriendsMain users={users} t={t} setDarkActive={setDarkActive}/>;
    }, [referrals]);

    return (
        <React.Fragment>
            <div className={`dark ${darkActive?"active":""}`}  style={{zIndex:"1"}}></div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <div
                    className="window-big invite-friends"
                    style={{ position: "relative" }}
                >
                    {getPageToShow()}
                </div>
            </div>
        </React.Fragment>
    );
};

export default InviteFriends;

import React from "react";
import "./connectplate.css";
import { useNavigate } from "react-router";
const ConnectPlate = (props) => {
    const {t}=props;
    const navigate=useNavigate();
    return (
        <div className="connect-plate text-white" onClick={()=>navigate("/add-wallet")}>
          
                <img src="/images/bucket-plant-left.png" />
        
            <span>{t("connect-wallet")}</span>
            
                <img src="/images/bucket-plant-right.png" />
          
        </div>
    );
};

export default ConnectPlate;

import React, { useEffect, useState } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Main from "./pages/main";
import HowToEarn from "./pages/howtoearn";
import {
    getReferrals,
    getTopUsers,
    getUserDetails,
} from "./Redux/Actions/userActions";
import NewUser from "./pages/newUser";
import AddWallet from "./pages/addWallet";
import TopUsers from "./pages/topUsers";
import InviteFriends from "./pages/inviteFriends";
import { getTasksList } from "./Redux/Actions/taskActions";
import Tasks from "./pages/tasksEarn";
import Language from "./pages/language";
import { preloadMultipleImages } from "./helpers/preloadImages";
import DailyBonus from "./components/dailyBonus/dailyBonus";
import { useTranslation } from "react-i18next";
import AirDrop from "./pages/airDrop";

const imagesArray = [
    "/images/arrow-circle.svg",
    "/images/arrow-left.png",
    "/images/brick.png",
    "/images/bucket-plant.png",
    "/images/bucket-plant-left.png",
    "/images/bucket-plant-right.png",
    "/images/checked.svg",
    "/images/coin.png",
    "/images/cross.png",
    "/images/default-avatar.png",
    "/images/en.png",
    "/images/FON.jpg",
    "/images/mario-alot.png",
    "/images/mario-fire.png",
    "/images/mario-flag.png",
    "/images/mario-green.png",
    "/images/mario-incoin.png",
    "/images/mario-loading.png",
    "/images/mario-main.png",
    "/images/mario-present.png",
    "/images/mario-star-hand.png",
    "/images/mushroom.png",
    "/images/pipe.png",
    "/images/pl.png",
    "/images/poop.png",
    "/images/question.png",
    "/images/ru.png",
    "/images/star.png",
    "/images/telegram.svg",
    "/images/tokens.png",
    "/images/ua.png",
    "/images/youtube.png",
];

function App() {
    const {t,i18n}=useTranslation();
    const [fetchedImages, setFetchedImages] = useState(false);
    const [dailyBonusOpened, setDailyBonusOpened] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tg = window.Telegram.WebApp;
    const userDetails = useSelector((state) => state.userDetails);
    const usersTop = useSelector((state) => state.usersTop);
    const { loading: loadingUsers, users, error } = usersTop;
    const { loading, data } = userDetails;

    const userReferrals = useSelector((state) => state.userReferrals);
    const { users: usersReferrals, loading: loadingReferrals } = userReferrals;

    const tasksList = useSelector((state) => state.tasksList);
    const { tasks, loading: loadingTasks } = tasksList;
    const changeLanguage=(language)=>{
        i18n.changeLanguage(language);
    }
    console.log(userReferrals, userDetails, usersTop);
    // tg.initDataUnsafe.user = {
    //     id: 484354212,
    //     first_name: "Morti",
    //     last_name: "</>",
    //     username: "eumorti",
    //     language_code: "ru",
    //     allows_write_to_pm: true,
    // };
    useEffect(() => {
        try {
            dispatch(getUserDetails(tg.initDataUnsafe.user));
        } catch (error) {
            tg.close();
        }
    }, [dispatch, tg]);

    useEffect(() => {
        if (!loading && typeof loading !== "undefined") {
            console.log(userDetails.isNewUser);

            tg.ready();
            tg.expand();

            if (userDetails.isNewUser) {
                switch (tg.initDataUnsafe.user.language_code) {
                    case "ru":
                        localStorage.setItem("language", "ru");
                        break;
                    case "uk":
                        localStorage.setItem("language", "ua");
                        break;
                    case "pl":
                        localStorage.setItem("language", "pl");
                        break;
                    default:
                        localStorage.setItem("language", "en");
                        break;
                }
                changeLanguage(localStorage.getItem("language"));
                navigate("/new-user");
            } else {
                const language = localStorage.getItem("language");
                if (language==null) {
                    switch (tg.initDataUnsafe.user.language_code) {
                        case "ru":
                            localStorage.setItem("language", "ru");
                            break;
                        case "pl":
                            localStorage.setItem("language", "pl");
                            break;
                        case "uk":
                            localStorage.setItem("language", "ua");
                            break;
                        default:
                            localStorage.setItem("language", "en");
                    }
                }
                changeLanguage(localStorage.getItem("language"));
            }
        }
    }, [userDetails, usersTop]);

    console.log(userDetails);

    useEffect(() => {
        if (!loading && typeof loading !== "undefined") {
            dispatch(getReferrals(tg.initDataUnsafe.user));
            dispatch(getTasksList(tg.initDataUnsafe.user));
            dispatch(getTopUsers());

            console.log(data.dailyBonusClaimed);

            if (differenceInDays(data.dailyBonusClaimed) >= 1) {
                setDailyBonusOpened(true);
            }
        }
    }, [dispatch, userDetails]);
    useEffect(() => {
        preloadMultipleImages(imagesArray, () => {
            setFetchedImages(true);
            console.log("callback");
        });
    }, []);

    const checkLoading = () => {
        return (
            !loading &&
            typeof loading !== "undefined" &&
            !loadingReferrals &&
            typeof loadingReferrals !== "undefined" &&
            !loadingUsers &&
            typeof loadingUsers !== "undefined" &&
            !loadingTasks &&
            typeof loadingTasks !== "undefined"
        );
    };
    console.log(fetchedImages);
    console.log(tg);

    if (!checkLoading() || !fetchedImages) {
        return (
            <div className="loading-window">
                <div>
                    <img src="/images/loading.gif" />
                </div>
            </div>
        );
    }
    if (tg.platform == "unknown" || tg.platform.includes("desktop")||tg.platform=="macos") {
        return (
            <div className="play-mobile">
                <p>Play on your mobile</p>
                <img src="/images/qr.png" />
                <span>@mariosupertoken_bot</span>
            </div>
        );
    }
    return (
        <Routes>
            <Route
                index
                element={
                    <Main
                        tg={tg}
                        userDetails={userDetails}
                        dailyBonusOpened={dailyBonusOpened}
                        setDailyBonusOpened={setDailyBonusOpened}
                        t={t}
                    />
                }
            />
            <Route
                path="/new-user"
                element={<NewUser userDetails={userDetails} t={t}/>}
            />
            <Route path="/earn" element={<HowToEarn t={t}/>} />
            <Route
                path="/add-wallet"
                element={<AddWallet userDetails={userDetails} t={t}/>}
            />
            <Route
                path="/top-users"
                element={<TopUsers userDetails={userDetails} t={t}/>}
            />
            <Route
                path="/invite-friends"
                element={<InviteFriends referrals={usersReferrals} t={t}/>}
            />
            <Route path="/tasks" element={<Tasks tasksList={tasksList} t={t}/>} />
            <Route path="/language" element={<Language tg={tg} t={t} changeLanguage={changeLanguage}/>} />
            <Route path="/air-drop" element={<AirDrop t={t}/>}/>
        </Routes>
    );
}

export default App;

//export const serverLink = "http://localhost:6222";
export const serverLink = "https://api.mario-token.cfd";
export const botLink = "https://t.me/mariosupertoken_bot";
export const languages = [
    {
        name: "English",
        image: "en.png",
        code: "en",
    },
    {
        name: "Українська",
        image: "ua.png",
        code: "ua",
    },
    {
        name: "Русский",
        image: "ru.png",
        code: "ru",
    },
    {
        name: "Polish",
        image: "pl.png",
        code: "pl",
    },
];

const differenceInDays = (date1) => {
    date1 = new Date(date1);

    let date2 = new Date(); // Вторая дата

    // Вычисляем разницу в миллисекундах
    let differenceInMilliseconds = date2 - date1;

    // Преобразуем разницу из миллисекунд в дни
    let differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    console.log(differenceInDays);

    return differenceInDays;
};

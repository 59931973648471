import axios from "axios";
import { serverLink } from "../../App";
import {
    GET_REFERRALS_REQUEST,
    GET_REFERRALS_SUCCESS,
    USER_ADD_WALLET_FAIL,
    USER_ADD_WALLET_REQUEST,
    USER_ADD_WALLET_SUCCESS,
    USER_DELETE_WALLET_REQUEST,
    USER_DELETE_WALLET_SUCCESS,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_SELECT_WALLET_REQUEST,
    USER_SELECT_WALLET_SUCCESS,
    USERS_TOP_REQUEST,
    USERS_TOP_SUCCESS,
} from "../Constans/userConstans";

export const getUserDetails = (props) => async (dispatch) => {
    try {
        dispatch({ type: USER_DETAILS_REQUEST });

        const { data } = await axios.post(serverLink + "/api/user", props);
        console.log(data);
        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        window.Telegram.WebApp.close();
    }
};

export const addNewWallet = (props) => async (dispatch) => {
    try {
        dispatch({ type: USER_ADD_WALLET_REQUEST });

        const { data } = await axios.post(
            serverLink + "/api/user/wallet",
            props
        );

        dispatch({ type: USER_ADD_WALLET_SUCCESS, payload: data });
    } catch (error) {
        const message =
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

            dispatch({type:USER_ADD_WALLET_FAIL,payload:message});
    }
};

export const selectWallet = (props) => async (dispatch) => {
    try {
        dispatch({ type: USER_SELECT_WALLET_REQUEST });

        const { data } = await axios.post(
            serverLink + "/api/user/wallet-set",
            props
        );

        dispatch({ type: USER_SELECT_WALLET_SUCCESS, payload: data });
        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        window.Telegram.WebApp.close();
    }
};

export const deleteWallet = (props) => async (dispatch) => {
    try {
        dispatch({ type: USER_DELETE_WALLET_REQUEST });

        const { data } = await axios.post(
            serverLink + "/api/user/wallet-delete",
            props
        );

        dispatch({ type: USER_DELETE_WALLET_SUCCESS, payload: data });
        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        window.Telegram.WebApp.close();
    }
};


export const getTopUsers=()=>async(dispatch)=>{
    try{
        dispatch({type:USERS_TOP_REQUEST});
        const {data}=await axios.post(serverLink+"/api/user/top");

        dispatch({type:USERS_TOP_SUCCESS,payload:data});

    }catch(error){
        window.Telegram.WebApp.close();
    }
}


export const getReferrals=(props)=>async(dispatch)=>{
    try{
        dispatch({type:GET_REFERRALS_REQUEST});
        const {data}=await axios.post(serverLink+"/api/user/referrals",props);
        console.log(data);
        dispatch({type:GET_REFERRALS_SUCCESS,payload:data});
    }catch(error){
        window.Telegram.WebApp.close();
    }
}
import React, { useEffect, useState } from "react";
import "./tasksearn.css";
import { useDispatch, useSelector } from "react-redux";
import { checkTaskList } from "../Redux/Actions/taskActions";
import { useNavigate } from "react-router";
const Tasks = (props) => {
    const {t}=props;
        const navigate=useNavigate();
        const tg = window.Telegram.WebApp;
        const dispatch=useDispatch();
        const checkTask=useSelector(state=>state.checkTask);
        const {loading,success}=checkTask;
    const [tasksGrouped, setTasksGrouped] = useState([]);
        const [taskModal,setTaskModal]=useState({opened:false,image:"",name:"",bonus:0,id:"",link:""});
        const tasksList=useSelector(state=>state.tasksList);
        const {tasks}=tasksList;
    const [blur,setBlur]=useState(false);


    useEffect(() => {
        if (tasks.length > 0) {
            setTasksGrouped(groupTasks(tasks));
        }
    }, [tasksList]);


    const openModalHandler=(image,name,bonus,id,link)=>{
        setTaskModal({opened:true,image,name,bonus,id,link});
        setBlur(true);
    }

    const closeModalHandler=()=>{
        setTaskModal({...taskModal,opened:false});
        setBlur(false);
    }

    const checkTaskHandler=(id)=>{
        dispatch(checkTaskList({taskId:id,...tg.initDataUnsafe.user}));
        closeModalHandler();
    }

    console.log(tasksList);
    const groupTasks = (tasks) => {
        const tasksGroup = [
            { name: "Super Mario Youtube", tasks: [] },
            { name: t("group-task"), tasks: [] },
        ];
    
        tasks.forEach((task) => {
            if (task.specials.youtube) {
                tasksGroup[0].tasks.push(task);
            } else {
                tasksGroup[1].tasks.push(task);
            }
        });
    
        return tasksGroup;
    };
    
    return (
        <div
            style={{ display: "flex", flexDirection: "column", height: "100%"}}
        >
            <div className={`dark ${blur?"active":""}`} style={{zIndex:"1"}}></div>
            <div className="window-big tasks-earn" style={{position:"relative"}}>
                <img src="/images/cross.png" width="16px" style={{position:"absolute",right:"5%",top:"3%"}} onClick={()=>navigate("/")}/>
                <div className="tasks-earn_header">
                    <span>
                        {t("tasks-earn_header")} -{" "}
                        <img
                            src="/images/star.png"
                            width={"32px"}
                            height={"32px"}
                        />
                    </span>
                    <img
                        src="/images/tokens.png"
                        className="tasks-earn_tokens"
                    />
                </div>
                <div className="separator"></div>

                <div className="tasks-earn_list">
                    {tasksGrouped.map((item) => (
                        <React.Fragment>
                            <div className="tasks-earn_item-header">
                                {item.name}
                            </div>
                            {item.tasks.map((task) => (
                                <div className="tasks-earn_item">
                                    <div style={{minWidth:"44px"}}> <img
                                        src={`/images/${task.image}`}
                                        className="tasks-earn_item-logo"
                                    /></div>
                                   
                                    <span className="tasks-earn_item-task">
                                        <span className="tasks-earn_item-task-name">
                                            {task.name[localStorage.getItem("language")||"en"]}
                                        </span>
                                        <span className="tasks-earn_bonus">
                                            +{task.bonus}{" "}
                                            <img
                                                src="/images/star.png"
                                                width={"12px"}
                                                height={"12px"}
                                            />{" "}
                                            Mario
                                        </span>
                                    </span>
                                    <span style={{ marginLeft: "auto",alignContent:"center" }}>
                                        <img
                                            src={`/images/${task.completed?"checked.svg":"arrow-circle.svg"}`}
                                            onClick={()=>openModalHandler(task.image,task.name[localStorage.getItem("language")||"en"],task.bonus,task._id,task.link)}
                                            style={{ height: "36px", }}
                                        />
                                    </span>
                                 
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                
                </div>
                <div className={`tasks-earn_modal ${taskModal.opened?"":"display-none"}`} style={{zIndex:"2"}}>
                        <img src={`/images/${taskModal.image}`} className="tasks-earn_modal-logo"/>
                        <img src="/images/cross.png" style={{width:"16px",position:"absolute",top:"5%",right:"5%"}} onClick={closeModalHandler}/>
                        <p className="text-white font-bold">{taskModal.name}</p>
                        <p className="text-white font-bold">+{taskModal.bonus} <img src="/images/star.png" style={{width:"16px"}}/> MARIO</p>
                       
                        {taskModal.link?<a href={taskModal.link} className="tasks-earn_modal-button" onClick={()=>checkTaskHandler(taskModal.id)}>{t("tasks-earn_modal-button")}</a>: <div className="tasks-earn_modal-button" onClick={()=>checkTaskHandler(taskModal.id)}>{t("tasks-earn_modal-button-check")}</div>}
                </div>
            </div>
        </div>
    );
};

export default Tasks;


import React, { useState } from "react";
import { useNavigate } from "react-router";
import { botLink } from "../../App";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getUserName } from "../../helpers/getUsername";
const InviteFriendsMain = (props) => {
    const [modalOpened,setModalOpened]=useState(false);
    const {users,t,setDarkActive}=props;
        const navigate=useNavigate();
        const tg = window.Telegram.WebApp;
        const [copied, setCopied] = useState(false);
        const copyHandler = () => {
       
            setCopied(true);
    
            setTimeout(() => {
                "set timeout";
                setCopied(false);
            }, 500);
        };
    return (
        <React.Fragment>
            <div className="invite-friends_header">
                {t("invite-friends_header-main")}{" "}
                <img
                    src="/images/cross.png"
                    width="16px"
                    height="16px"
                    onClick={() => navigate("/")}
                    style={{ position: "absolute", right: 0 }}
                />
            </div>
            <div className="separator"></div>

            <div className="invite-friends_prices">
                <div className="invite-friends_prices-item">
                    <p className="invite-friends_prices-item-text">
                        {t("invite-friends_prices-item-text")}
                    </p>
                    <p className="invite-friends_prices-item-price">
                        <img src="/images/star.png" />
                        <span>+100</span>
                    </p>
                </div>
                <div className="invite-friends_prices-item">
                    <p className="invite-friends_prices-item-text">
                        {t("invite-friends_prices-item-text-premium")}
                    </p>
                    <p className="invite-friends_prices-item-price">
                        <img src="/images/star.png" />
                        <span>+300</span>
                    </p>
                </div>
            </div>
            <div className="separator" style={{ marginTop: "1.5rem" }}></div>

            <div className="invite-friends_referals-text">
                {t("invite-friends_referals-text")}: <span className="text-red">{users.length}</span>
            </div>
            <div className="invite-friends_referals-list">
                {users.map(user=> <div className="invite-friends_referals-item">
                    <div className="invite-friends_referals-avatar">
                        <img src="/images/default-avatar.png" />
                    </div>
                    <span className="invite-friends_referals-nickname">
                        {getUserName(user)}
                    </span>
                    <span className="invite-friends_referals-tokens">
                        +{user.is_premium?"300":"100"}
                        <img
                            src="/images/star.png"
                            width="20px"
                            height="20px"
                        />
                        
                    </span>
                </div>)}
                
            
                
            </div><div
                className="invite-friends_button"
                style={{marginTop:"auto",marginBottom:"1rem"}}
                onClick={() => {setModalOpened(true);setDarkActive(true)}}
            >
                {t("invite-friends_button")}
            </div>
            <div
                className={`invite-friend_modal ${
                    modalOpened ? "" : "display-none"
                }`}
                style={{zIndex:"2"}}
            >
                <div
                    className="invite-friends_modal-header"
                    style={{ position: "relative" }}
                >
                    {t("invite-friends_modal-header")}
                    <img
                        src="/images/cross.png"
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "16px",
                            height: "16px",
                        }}
                        onClick={() => {setModalOpened(false);setDarkActive(false)}}
                    />
                </div>
                <div
                    className="separator"
                    style={{ marginTop: "1.5rem" }}
                ></div>
                <CopyToClipboard
                    text={`${botLink}?start=${tg.initDataUnsafe.user.id}`}
                    onCopy={copyHandler}
                >
                    <div className={`invite-friends_modal-button ${copied?"active":""}`}>
                        {t("invite-friends_modal-button")}
                    </div>
                </CopyToClipboard>
                <div className="invite-friends_modal-button">
                    <a
                        href={`https://t.me/share/url?url=${botLink}?start=${tg.initDataUnsafe.user.id}&text=${t("invite-friends_modal-button-button")}`}
                    >
                        {t("invite-friends_modal-button-text")}
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default InviteFriendsMain;

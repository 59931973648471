import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import Tokens from "../components/tokens/tokens";
import MarioMain from "../components/marioimages/marioMain";
import ConnectPlate from "../components/connectplate/connectplate";
import HomeButtons from "../components/homebuttons/homeButtons";
import DailyBonus from "../components/dailyBonus/dailyBonus";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../Redux/Actions/userActions";

const Main = (props) => {
   const {dailyBonusOpened,setDailyBonusOpened,t}=props;
    const { tg,userDetails } = props;
    const userData = tg.initDataUnsafe.user;
    const {data}=userDetails;
    const {tokens}=data;
  
  

   

    return (
        <div
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
            
            <div className={`dark ${dailyBonusOpened?"active":""}`}></div>
            
            <Header userData={userData}/>
            <Tokens tokens={tokens}/>
            <MarioMain />
            <ConnectPlate t={t}/>
            <HomeButtons t={t}/>
            <DailyBonus dailyBonusOpened={dailyBonusOpened} setDailyBonusOpened={setDailyBonusOpened} t={t}/>
        </div>
    );
};

export default Main;

